/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { loadImages } from "../../utility/helpers"
import "./Avatar.css"

export default function Avatar({ className, image, onClick }) {
  const [ready, setReady] = useState(false)
  useEffect(() => {
    loadImages(image).then(() => {
      setReady(true)
    })
  }, [])
  return (
    <div
      className={`${className} avatar ${onClick ? "clickable" : ""}`}
      data-ready={ready}
      onClick={onClick}>
      <div
        className="avatar-image"
        style={{ backgroundImage: `url(${image})` }}
      />
    </div>
  )
}
