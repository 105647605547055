import { useEffect } from "react"
import { useSelector } from "react-redux"
import { Dialog } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { cognito } from "../../modules/cognito"
import { analytics } from "../../modules/analytics"
import "./ProfilePopup.css"

const ProfilePopup = ({ open, onClose, data }) => {
  // animations state
  const animations = useSelector(states => states.common.animations)
  // log on open
  useEffect(() => {
    if (open) { analytics.logEvent("OPEN_PROFILE") }
  }, [open])
  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={onClose}
      transitionDuration={animations === "true" ? undefined : 0}
      classes={{ paper: "profile-popup" }}>
      <div className="profile-popup-header">
        <button
          className="profile-popup-close-button"
          onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
      <div className="profile-popup-title">
        My 747 Profile
      </div>
      <div className="profile-popup-image"
        style={{ backgroundImage: `url(${data?.image})` }}
      />
      <div className="profile-popup-name">
        {data?.name}
      </div>
      <button
        className="profile-popup-logout"
        onClick={() => cognito.authenticate("logout")}>
        Logout
      </button>
    </Dialog>
  )
}

export default ProfilePopup
