// module constants
const baseUrl = process.env.REACT_APP_COGNITO_BASE_URL
const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID
const redirectUri = window.location.origin + window.location.pathname

// helper to get url search param
const getSearchParam = key => {
  const url = window.location.href
  const separator = `${key}=`
  if (url.includes(separator)) {
    return url.split(separator)[1].split("&")[0].split("#")[0]
  }
  return null
}

// helper to get token payloads
const getTokenPayload = token => {
  try {
    // decode token payload
    return JSON.parse(window.atob(token.split(".")[1]))
  } catch (err) {
    return null
  }
}

// helper to check token expiry time
const isTokenExpired = token => {
  try {
    // decode token payload
    const payload = getTokenPayload(token)
    // check with current time
    return payload.exp * 1000 < Date.now()
  } catch (err) {
    return true
  }
}

export const cognito = {
  /**
   *  Login, signup or logout through Amazon Cognito
   * @param {'login' | 'signup' | 'logout'} type - Authentication type
   */
  authenticate(type, redirect = true) {
    // clear local tokens if on logout
    if (type === "logout") {
      this.clearLocalTokens()
    }
    // generate cognito url with params
    const endpoint = new URL(`${baseUrl}/${type}`)
    endpoint.searchParams.append("client_id", clientId)
    endpoint.searchParams.append("response_type", "token")
    endpoint.searchParams.append("scope", "email openid profile")
    endpoint.searchParams.append("redirect_uri", redirectUri)
    // navigate to cognito
    return redirect
      ? window.location.href = endpoint.toString()
      : endpoint.toString()
  },
  /** Check tokens on redirect url */
  checkURLTokens() {
    // get tokens from url
    const idToken = getSearchParam("id_token")
    const accessToken = getSearchParam("access_token")
    // check tokens
    if (idToken && accessToken) {
      localStorage.setItem("id_token", idToken)
      localStorage.setItem("access_token", accessToken)
      // token found in url
      return true
    }
    // no token in url
    return false
  },
  /** Get tokens from the local storage */
  getLocalTokens() {
    // get tokens locally
    const idToken = localStorage.getItem("id_token")
    const accessToken = localStorage.getItem("access_token")
    // no local tokens
    if (!idToken || !accessToken) {
      return null
    }
    // tokens are expired
    if (isTokenExpired(idToken) || isTokenExpired(accessToken)) {
      // remove local tokens
      this.clearLocalTokens()
      return null
    }
    // return valid token object
    return { idToken, accessToken }
  },
  /** Cleat tokens on local storage */
  clearLocalTokens() {
    localStorage.removeItem("id_token")
    localStorage.removeItem("access_token")
  },
  /** Get payload objects of tokens */
  getTokenPayloads() {
    // get local tokens
    const tokens = this.getLocalTokens()
    // tokens are available
    if (tokens) {
      // return payloads
      return {
        idToken: getTokenPayload(tokens.idToken),
        accessToken: getTokenPayload(tokens.accessToken)
      }
    } else {
      // tokens not available
      return null
    }
  },
  /** Fetch user details */
  getUserData() {
    return new Promise(async resolve => {
      const tokens = this.getLocalTokens()
      // no tokens found
      if (!tokens) { resolve(null) }
      // request user data
      const resp = await fetch(`${baseUrl}/oauth2/userInfo`, {
        headers: {
          Authorization: `Bearer ${tokens.accessToken}`,
        }
      })
      // resolve user data
      resolve(await resp.json())
    })
  }
}
