import "./Flags.css"

export default function Flags({ data }) {
  return data && (
    <>
      {
        !data.paidGame && data.winnerCount > 0 ? (
          <div className="flag flag-ribbon free-rewards" />
        ) : data.paidGame ? (
          <div className="flag flag-ribbon paid-rewards" />
        ) : ""
      }
      {
        data.globalLb && (
          <div className="flag flag-icon global" />
        )
      }
    </>
  )
}
