/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { CircularProgress } from "@mui/material"
import { cognito } from "../modules/cognito"
import { analytics } from "../modules/analytics"
import {
  useCheckSubscriptionMutation
} from "../services/apis/paymentAPI"
import "./Game.css"

export default function Game({ active }) {
  // api mutations
  const [checkSubscription] = useCheckSubscriptionMutation()
  // current game data
  const data = useSelector(states => states.common.game)
  // loading state
  const [loading, setLoading] = useState(true)
  // ready state
  const [ready, setReady] = useState(false)
  const [hidden, setHidden] = useState(true)
  // recheck payment before game launch
  useEffect(() => {
    if (active && data) {
      setReady(false)
      setHidden(false)
      setLoading(true)
      setTimeout(() => {
        if (data.paidGame) {
          checkSubscription({ leaderboardUuid: data.leaderboardUuid }).then(status => {
            if (status.error) {
              // payment check error
              window.history.back()
            } else {
              if (status.data === true) {
                // paid for the game
                analytics.logEvent("OPEN_GAME", { game_name: data?.name })
                setReady(true)
              } else if (status.data === false) {
                // not paid for the game
                window.history.back()
              }
            }
          })
        } else {
          // free to play game
          analytics.logEvent("OPEN_GAME", { game_name: data?.name })
          setReady(true)
        }
      }, 300)
    } else {
      setTimeout(() => setHidden(true), 300)
    }
  }, [active])

  // message bridge
  useEffect(() => {
    const handler = event => {
      const target = document.querySelector("#iframe")
      if (typeof event.data === "string") {
        try {
          let commandObj = JSON.parse(event.data)
          if (commandObj.command === "HIDE_NAV") {
            // token callback
            target.contentWindow.getData(cognito.getLocalTokens()?.accessToken, null)
          } else if (commandObj.command === "EXIT") {
            // exit callback
            window.history.back()
            analytics.logEvent("EXIT_GAME", { game_name: data?.name })
          } else if (commandObj.command === "SUBSCRIBE") {
            // subscribe callback
          } else if (commandObj.command === "LOGIN") {
            // login callback
          }
        } catch (e) {
          console.log("Invalid Message " + e)
        }
      }
    }
    window.addEventListener("message", handler)
    return () => window.removeEventListener("message", handler)
  }, [])
  if (!data) { return }
  // game frame
  return (
    <div className={`game ${active ? "active" : "inactive"} ${(active || !hidden) ? "animate" : "static"}`}>
      {
        loading && (
          <div className="game-loading">
            <div
              className="game-loading-logo"
              style={{
                backgroundImage: `url(${data.title_image})`
              }}
            />
            <CircularProgress />
          </div>
        )
      }
      {
        !hidden && ready && (
          <iframe
            id="iframe"
            title={data.name}
            src={`${data.url}?platform=pwa&version=200`}
            style={{ opacity: loading ? 0 : 1 }}
            allow="microphone accelerometer encrypted-media"
            onLoad={() => setLoading(false)}
            onError={() => window.history.back()}
          />
        )
      }
    </div>
  )
}
