import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { getEnvironment, getHeaders } from "../../utility/helpers"
import { cognito } from "../../modules/cognito"

const commonAPI = createApi({
  reducerPath: "commonAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
  }),
  endpoints: builder => ({
    getData: builder.mutation({
      query: () => ({
        url: `/user/v1/id-token/${cognito.getLocalTokens()?.idToken}`,
        method: "GET",
        headers: getHeaders()
      }),
      transformResponse: response => response.data
    }),
    getSections: builder.mutation({
      query: () => ({
        url: `/user/v1/games/categories/${getEnvironment()}`,
        method: "GET",
        headers: getHeaders(),
      }),
      transformResponse: response => response.data
    }),
    getSectionGames: builder.mutation({
      query: ({ uuid, page, limit }) => ({
        url: `/user/v1/games/by/category/${uuid}/${limit}/${page}`,
        method: "GET",
        headers: getHeaders(),
      }),
      transformResponse: response => response.data
    }),
    searchGames: builder.mutation({
      query: ({ query }) => ({
        url: `/user/v1/games/by/name/${getEnvironment()}/${query}`,
        method: "GET",
        headers: getHeaders(),
      }),
      transformResponse: response => response.data
    }),
    getGlobalLeaderboard: builder.mutation({
      query: ({ msisdn, limit, page }) => ({
        url: `/game/v1/leaderboard/redis/global/${msisdn}/${limit}/${page}`,
        method: "GET",
        headers: getHeaders()
      }),
      transformResponse: response => response.data
    }),
    getAffiliate: builder.mutation({
      query: ({ id }) => ({
        url: `/user/v1/affiliate/${id}`,
        method: "GET",
        headers: getHeaders()
      })
    })
  })
})

export const {
  useGetDataMutation,
  useGetSectionsMutation,
  useGetSectionGamesMutation,
  useSearchGamesMutation,
  useGetGlobalLeaderboardMutation,
  useGetAffiliateMutation
} = commonAPI

export default commonAPI
