/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { setGame } from "../../services/store"
import Shine from "../../components/Shine"
import Flags from "../../components/Flags"
import { getGameDetails, loadImages, pushRouter } from "../../utility/helpers"
import "./SliderCard.css"

export default function SliderCard({ data }) {
  const dispatch = useDispatch()
  const [ready, setReady] = useState(false)
  useEffect(() => {
    if (!data) { return }
    loadImages(data.bg_image, data.title_image).then(() => {
      setReady(true)
    })
  }, [data])
  return (
    <div
      className="slider-card" data-ready={data ? true : false}>
      <div className="slider-card-inner">
        <div className="slider-card-image-outer" data-ready={ready}>
          {
            data && (
              <div className="slider-card-image"
                style={{
                  backgroundImage: `url(${data.title_image}), url(${data.bg_image})`
                }}>
                <Flags data={data} />
                <Shine
                  visible={ready && data.winnerCount > 0}
                  color={data.paidGame ? "gold" : "white"}
                />
              </div>
            )
          }
        </div>
        <div className="slider-card-title">
          {data?.name}
        </div>
        <div className="slider-card-footer">
          <div className="slider-card-details">
            {getGameDetails(data)}
          </div>
          <div className="slider-card-content-buttons">
            {
              data && (
                <button
                  className="slider-card-button"
                  onClick={() => {
                    dispatch(setGame(data))
                    pushRouter("view")
                  }}>
                  Play
                </button>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}
