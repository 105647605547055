/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useGetFavoriteGamesMutation } from "../services/apis/favoritesAPI"
import { CircularProgress } from "@mui/material"
import ListingCard from "../components/cards/ListingCard"
import "./FavoritesPage.css"

export default function FavoritesPage() {
  // api mutations
  const [getFavorites] = useGetFavoriteGamesMutation()
  // hash router path
  const router = useSelector(states => states.common.router)
  // favorite games list
  const [games, setGames] = useState([])
  // current page
  const [page, setPage] = useState(0)
  // total count
  const [total, setTotal] = useState(0)
  // loading status
  const [loading, setLoading] = useState(true)
  // method to load data
  const loadData = (page = 0) => {
    setLoading(true)
    if (page === 0) { setGames([]) }
    getFavorites({ page, limit: 20 }).then(resp => {
      setTimeout(() => {
        if (!resp.error && resp.data.dataRows) {
          setGames(
            page === 0
              ? resp.data.dataRows
              : [...games, ...resp.data.dataRows]
          )
          setPage(page)
          setTotal(resp.data.totalPages)
        }
        setLoading(false)
      }, 500)
    })
  }
  // load on mount
  useEffect(() => {
    if (router.length === 0 || router[0] === "") { loadData() }
  }, [router])
  if (games.length === 0 && loading) {
    return (
      <div className="favorites-page loading">
        <CircularProgress />
      </div>
    )
  }
  // favorites list
  return (
    <div className="favorites-page">
      <div className="favorites-page-title">
        Your Favorites
      </div>
      {
        games.length > 0 ? (
          <div>
            <div className="favorites-page-content listing">
              {
                games.map(item => (
                  <ListingCard
                    key={item.id}
                    data={item}
                  />
                ))
              }
            </div>
            {
              !loading && page < total - 1 && (
                <div className="favorites-page-footer">
                  <button
                    className="favorites-page-footer-button"
                    onClick={() => loadData(page + 1)}>
                    Load More
                  </button>
                </div>
              )
            }
          </div>
        ) : (
          <div className="favorites-page-content listing">
            <div className="favorites-page empty">
              You haven't added any favorite games yet.
            </div>
          </div>
        )
      }
    </div>
  )
}
