import { createSlice, configureStore, isRejected } from "@reduxjs/toolkit"
import commonAPI from "./apis/commonAPI"
import emailAPI from "./apis/emailAPI"
import favoritesAPI from "./apis/favoritesAPI"
import paymentAPI from "./apis/paymentAPI"
import { cognito } from "../modules/cognito"

const slice = createSlice({
  name: "commonSlice",
  initialState: {
    // current theme
    theme: localStorage.getItem("theme") || "dark",
    // animations enabled theme
    animations: localStorage.getItem("animations") || "true",
    // current screen state
    screen: "login",
    // current hash router path
    router: [],
    // current game data
    game: null,
    // list of games
    games: [],
    // user data
    user: null,
    // state flags
    flags: {
      global: false
    }
  },
  reducers: {
    setTheme(state, action) {
      state.theme = action.payload
    },
    setAnimations(state, action) {
      state.animations = action.payload
    },
    setScreen(state, action) {
      state.screen = action.payload
    },
    setRouter(state, action) {
      state.router = action.payload.replace("#", "").split(":")
    },
    setGame(state, action) {
      state.game = action.payload
    },
    setGames(state, action) {
      state.games = action.payload
    },
    setUser(state, action) {
      state.user = action.payload
    },
    setFlags(state, action) {
      Object.assign(state.flags, action.payload)
    }
  }
})

export const middleware = () => next => action => {
  if (isRejected(action)) {
    if (action.payload.status === 401) {
      cognito.clearLocalTokens()
      window.location.reload()
    }
  }
  return next(action)
}

export const store = configureStore({
  reducer: {
    common: slice.reducer,
    [commonAPI.reducerPath]: commonAPI.reducer,
    [emailAPI.reducerPath]: emailAPI.reducer,
    [favoritesAPI.reducerPath]: favoritesAPI.reducer,
    [paymentAPI.reducerPath]: paymentAPI.reducer
  },
  middleware: getDefaultMiddleware => (
    getDefaultMiddleware()
      .concat(commonAPI.middleware)
      .concat(emailAPI.middleware)
      .concat(favoritesAPI.middleware)
      .concat(paymentAPI.middleware)
      .concat(middleware)
  )
})

export const {
  setTheme,
  setAnimations,
  setScreen,
  setRouter,
  setGame,
  setGames,
  setUser,
  setFlags
} = slice.actions
